import { GetterTree } from 'vuex'
import { transformVariablesTree } from '@/utils/useTreeHelpers'
import { TreeStateInterface } from '@/models/store/TreeState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'

const getters: GetterTree<TreeStateInterface, RootStateInterface> = {
  loadedEditorVariables(
    state: TreeStateInterface,
    getters: { displayFoldersFirst: boolean }
  ) {
    return transformVariablesTree({
      tree: state.tree,
      foldersFirst: getters.displayFoldersFirst
    })
  },
  displayFoldersFirst(state: TreeStateInterface) {
    return state.kind === 'variables'
  }
}

export default getters
