import { VariableNodeInterface } from '@/models/tree/VariableNode.interface'
import { sortVariableNodes } from '@/utils/useSimpleTransformations'

export const findVariableNode = (
  tree: VariableNodeInterface[],
  id: number
): VariableNodeInterface | undefined => {
  let nodeFound = tree.find((node) => node.id === id)
  if (nodeFound) {
    return nodeFound
  }
  tree.every((node) => {
    nodeFound = findVariableNode(node.children, id)
    return !nodeFound
  })
  return nodeFound
}

export const transformVariablesTree = ({
  tree,
  foldersFirst
}: {
  tree: VariableNodeInterface[]
  foldersFirst?: boolean
}) => {
  foldersFirst = typeof foldersFirst === 'undefined' ? false : foldersFirst

  const nodes: VariableNodeInterface[] = tree.map((node) => {
    if (node.folder) {
      if (typeof node.$folded === 'undefined') {
        node.$folded = true
      }

      if (node.children.length > 0) {
        node.children = transformVariablesTree({
          tree: node.children,
          foldersFirst
        })
        node.children = sortVariableNodes({
          nodes: node.children,
          foldersFirst
        })
      }
    } else {
      node.$droppable = false
    }

    return node
  })

  return sortVariableNodes({ nodes, foldersFirst })
}
