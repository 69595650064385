import { ActionTree } from 'vuex'
import {
  createEditorVariableNode as createEditorVariableNodeRequest,
  deleteEditorVariableNode as deleteEditorVariableNodeRequest,
  updateEditorVariableNode as updateEditorVariableNodeRequest,
  fetchTargetVariables,
  fetchEditorVariables,
  fetchSurveys
} from '@/api_client'
import { surveysToTree } from '@/store/tree/helpers/useTreeHelpers'
import { mapTreeKindToType } from '@/utils/useSimpleTransformations'
import { transformVariablesTree } from '@/utils/useTreeHelpers'
import { TreeStateInterface } from '@/models/store/TreeState.interface'
import { VariableNodeInterface } from '@/models/tree/VariableNode.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'
import { SurveyInterface } from '@/models/surveys/Survey.interface'
import { TargetType } from '@/models/templates/Template.interface'

const actions: ActionTree<TreeStateInterface, RootStateInterface> = {
  async loadEditorVariables(
    { commit, dispatch, rootState },
    { kind, query, parentId, onlyChildren }
  ) {
    commit('loadingEditorVariables')

    const type: string = mapTreeKindToType(kind)
    let tree: VariableNodeInterface[]

    if (kind === 'variables') {
      const isTargetUndefinedOrChanged =
        !rootState.preview.targetId ||
        rootState.templates.template.target !== rootState.preview.targetType

      if (isTargetUndefinedOrChanged) {
        await dispatch(
          'preview/loadTargetData',
          { targetType: rootState.templates.template.target },
          { root: true }
        )
      }

      const data = await fetchTargetVariables(
        rootState.preview.environment!,
        rootState.templates.template.target!,
        rootState.preview.targetId!
      )

      tree = data.variables
    } else if (kind === 'surveys') {
      const { page, perPage } = rootState.pagination

      const { data, totalCount } = await fetchSurveys({ page, perPage, query })
      tree = surveysToTree(data as SurveyInterface[])

      dispatch('pagination/updateTotalCount', totalCount, {
        root: true
      })
    } else {
      const { page, perPage } = rootState.pagination
      const params = {
        kind: type,
        page,
        perPage,
        query,
        parentId,
        onlyChildren,
        filter: {} as {
          template?: { target: TargetType }
        }
      }
      if (params.kind === 'snippet')
        params.filter.template = {
          target: rootState.templates.template.target!
        }

      const { data, totalCount } = await fetchEditorVariables(params)
      tree = data

      dispatch('pagination/updateTotalCount', totalCount, {
        root: true
      })
    }

    commit('loadedEditorVariables', tree)
  },
  async loadEditorVariableChildren({ getters }, parent) {
    const response = await fetchEditorVariables({
      kind: parent.kind,
      parentId: parent.id
    })
    const foldersFirst = getters.displayFoldersFirst

    return transformVariablesTree({ tree: response.data, foldersFirst })
  },
  async createEditorVariableNode(
    { commit },
    { data, successCallback, failureCallback = () => undefined }
  ) {
    const newNode: VariableNodeInterface =
      await createEditorVariableNodeRequest(
        data,
        successCallback,
        failureCallback
      )
    commit('createEditorVariableNode', newNode)
  },
  async deleteEditorVariableNode({ commit }, id) {
    await deleteEditorVariableNodeRequest(id)
    commit('deleteEditorVariableNode', id)
  },
  async updateEditorVariableNode(
    { commit },
    {
      data,
      successCallback = () => undefined,
      failureCallback = () => undefined
    }
  ) {
    const newData: VariableNodeInterface =
      await updateEditorVariableNodeRequest(
        data.id,
        data,
        successCallback,
        failureCallback
      )
    commit('updateEditorVariableNode', {
      ...data,
      parentId: newData.parentId
    })
  },
  async filterEditorVariableNodes({ dispatch }, { kind, query }) {
    await dispatch('loadEditorVariables', {
      kind,
      query,
      parentId: undefined,
      onlyChildren: false
    })
    dispatch('pagination/updatePage', 1, { root: true })
  },
  toggleTreeView({ commit }) {
    commit('toggleTreeView')
  },
  setTreeKind({ commit, dispatch }, kind) {
    commit('setTreeKind', kind)
    dispatch('loadEditorVariables', { kind })
    dispatch('pagination/resetState', null, { root: true })
  }
}

export default actions
