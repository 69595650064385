import { SurveyInterface } from '@/models/surveys/Survey.interface'
import { VariableNodeInterface } from '@/models/tree/VariableNode.interface'

const surveysToTree = (surveys: SurveyInterface[]): VariableNodeInterface[] => {
  return surveys.map((survey) => {
    const options = survey.options.filter(({ archived }) => !archived)

    const children = options.map((option) => {
      return {
        name: option.name,
        folder: false,
        code: `{% survey_url ${survey.id}, ${option.id} %}`
      }
    })

    return {
      name: survey.name,
      folder: true,
      children
    } as VariableNodeInterface
  })
}

export { surveysToTree }
