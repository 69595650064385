import { ActionTree } from 'vuex'
import { SurveysStateInterface } from '@/models/store/SurveysState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'
import {
  SurveyInterface,
  SurveyOptionInterface
} from '@/models/surveys/Survey.interface'
import { OptionCountInterface } from '@/models/surveys/OptionCount.interface'
import {
  fetchSurvey,
  fetchSurveys,
  createSurvey as createSurveyRequest,
  updateSurvey as updateSurveyRequest,
  fetchOptionCounts
} from '@/api_client'

const actions: ActionTree<SurveysStateInterface, RootStateInterface> = {
  async createSurvey({ commit, state }) {
    commit('submittingSurvey')

    const data = {
      ...state.survey,
      optionsAttributes: state.survey.options,
      options: []
    }

    const survey: SurveyInterface = await createSurveyRequest(data).finally(
      () => commit('submittedSurvey')
    )

    commit('updateSurveyState', survey)
  },
  async loadSurvey({ commit }, id: number) {
    commit('loadingSurvey')

    const survey: SurveyInterface = await fetchSurvey(id).finally(() =>
      commit('loadedSurvey')
    )

    commit('updateSurveyState', survey)
  },
  async updateSurvey({ commit, state }) {
    commit('submittingSurvey')

    const data = {
      ...state.survey,
      optionsAttributes: state.survey.options,
      options: []
    }

    const survey: SurveyInterface = await updateSurveyRequest(data).finally(
      () => commit('submittedSurvey')
    )

    commit('updateSurveyState', survey)
  },
  async loadSurveys({ commit, state, rootState, dispatch }) {
    commit('loadingSurveys')

    const { page, perPage } = rootState.pagination
    const params = { page, perPage, ...state.filterAndSearch }

    const { data, totalCount } = await fetchSurveys(params).finally(() =>
      commit('loadedSurveys')
    )

    commit('updateSurveys', data as SurveyInterface[])
    dispatch('pagination/updateTotalCount', totalCount, { root: true })
  },
  async setFiltersAndLoad({ dispatch }, filters) {
    dispatch('setFilters', filters)
    dispatch('loadSurveys')
  },
  async loadOptionCounts({ commit }, surveyId: number) {
    commit('loadingOptionCounts')

    const params = { surveyId, environment: 'de' }

    const { data } = await fetchOptionCounts(params).finally(() =>
      commit('loadedOptionCounts')
    )

    commit('updateOptionCounts', data as OptionCountInterface[])
  },
  setFilters({ commit }, filters) {
    commit('setFilters', filters)
  },
  updateSurveyState({ commit }, data) {
    commit('updateSurveyState', data)
  },
  addNewOption({ commit }) {
    commit('addNewOption')
  },
  removeOption({ commit }, option) {
    commit('removeOption', option)
  },
  updateOption({ commit }, { option, data }) {
    commit('updateOption', { option, data })
  },
  archiveOption({ dispatch }, option: SurveyOptionInterface) {
    dispatch('updateOption', { option, data: { archived: true } })
  },
  unarchiveOption({ dispatch }, option: SurveyOptionInterface) {
    dispatch('updateOption', { option, data: { archived: false } })
  },
  resetSurvey({ commit }) {
    commit('resetSurvey')
  }
}

export default actions
